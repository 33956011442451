import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLoader } from '../Reducers/LoaderProvider';
import Header from './HomeNav';
import Footer from './Footer';

function Faqs() {
  const { setLoading } = useLoader();
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1500)
  }, [])
  const navigation=useNavigate()
  const faqItems = [
    {
      question: "How can I list my property on WELRM?",
      answer: "Listing is simple! Go to our 'List Your Property' section, provide details about your property, and our team will guide you through the onboarding process"
    },
    {
      question: "Are there any charges for listing my property?",
      answer: "No, there is no cost to list your property with Welrm."
    },
    {
      question: "How to contact WelRM team?",
      answer: "You can fill the contact us form from the website or check the contact us or support page."
    },
    // {
    //   question: "How to pay monthly listing fee?",
    //   answer: "From the Subscription tab, you can pay monthly listing fee to select Pay Now button."
    // },
    {
      question: "How can I change the availability of rooms on the website or application?",
      answer: "From Room Listings, user can see the all added rooms, and for each room, there is an edit button. The user can edit room availability from there."
    },
    {
      question: "Can I register on WelRM platform if I have less than 10 rooms?",
      answer: "Yes, Hotel partners can register with us even if they have fewer than 10 rooms."
    },
    {
      question: "Is there any commission charges that I have to pay to WelRM?",
      answer: "Yes, WelRM is charging minimal cost i.e. 15% commission per booking."
    },
    {
      question: "Can I change the price of a Room?",
      answer: "Yes, WELRM charges a small commission per booking to help manage the platform."
    },
    {
      question: "What benefits will my Hotel get from using your website or application?",
      answer: "By using our platforms, your hotel will benefit from increased visibility, streamlined operations, and better guest satisfaction."
    },
    {
      question: "How do I know my data is safe and secure with your website or application?",
      answer: "Our platforms uses industry-standard encryption and follows strict data protection policies to ensure your information is safe."
    }
  ];

  return (
    <>
    <Header/>
    <Box sx={{
      padding: { xs: "20px 80px", sm: "20px 80px", md: "20px 80px", lg: "20px 80px" },
      backgroundColor: "#F1F1F1",
      minHeight: "100vh",
    }}>
      <Box
        sx={{
          padding: { xs: "20px", md: "40px" },
          borderRadius: "10px",
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            color: "#000000",
            mb: 4,
          }}
        >
          Frequently Asked Questions (FAQs)
        </Typography>

        <Box sx={{ mb: 4 }}>
          {faqItems.map((item, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#000000",
                  mb: 1,
                }}
              >
                {index + 1} {item.question}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#666666",
                  pl: 2,
                  position: "relative",
                  "&::before": {
                    content: '"•"',
                    position: "absolute",
                    left: 0,
                    color: "#666666",
                  }
                }}
              >
                {item.answer}
              </Typography>
            </Box>
          ))}
        </Box>

        {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={()=>navigation(-1)}
            variant="contained"
            sx={{
              bgcolor: '#E0E0E0',
              color: '#000000',
              textTransform: 'none',
              '&:hover': {
                bgcolor: '#D0D0D0',
              },
              px: 4,
              py: 1,
              borderRadius: '8px',
            }}
          >
            Back
          </Button>
        </Box> */}
      </Box>
    </Box>
    <Footer/>
    </>
  );
}

export default Faqs;