// import React, { useEffect, useState } from 'react'
// import BookingAlert from './BookingAlert'
// import axios from 'axios';
// import { apiUrl } from '../../Shared/shared';

// function GuestMessage() {
//   // const [bookingAlert, setBookingAlert] = useState(false);
//   // const [promotionalAlert, setPromotionalAlert] = useState(false);
//   // const [reviewAlert, setReviewAlert] = useState(false);
//   const [data, setData] = useState("");
//   useEffect(() => {
//       fetchData();
//      // Check if the token is available in localStorage
//   }, []);
//     const fetchData = async () => {
//     const token = localStorage.getItem("token");
//     if (!token) {
//       console.log("Authentication token not found. Please log in again.");
//       return;
//     }

//     const config = {
//       headers: {
//         "oauth-token": token, // Send the token with the oauth-token key in headers
//         "Content-Type": "application/json",
//       },
//     };

//     try {
//       const response = await axios.get(`${apiUrl}/user/notifications`, config);
//       setData(response?.data);
//     } catch (error) {
//       console.error("Error fetching user data", error);
//       // toast.error("Failed to fetch user data");
//     }
//   };
//   return (
//     // <div className="bg-white rounded-xl  shadow-lg w-full mx-auto mx-14 mt-8 font-medium">
//     //   <h2 className="text-2xl font-semibold  mb-6 border-b-2 p-2">Booking Alerts</h2>

//     //   <div className="space-y-4 p-6">
//     //     {/* Booking Notifications */}
//     //     <div className="flex justify-between items-center">
//     //       <li className="text-lg">Booking Notifications:</li>
//     //       <label className="relative inline-flex items-center cursor-pointer">
//     //         <input
//     //           type="checkbox"
//     //           checked={bookingAlert}
//     //           onChange={(e) => setBookingAlert(e.target.checked)}
//     //           className="sr-only peer"
//     //         />
//     //         <div className="w-11 h-6 bg-gray-700 rounded-full peer-checked:bg-primary peer-checked:after:translate-x-5 after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
//     //         </label>
//     //     </div>

//     //     {/* Promotional Offers */}
//     //     <div className="flex justify-between items-center">
//     //       <li className="text-lg">Promotional Offers:</li>
//     //       <label className="relative inline-flex items-center cursor-pointer">
//     //         <input
//     //           type="checkbox"
//     //           checked={promotionalAlert}
//     //           onChange={(e) => setPromotionalAlert(e.target.checked)}
//     //           className="sr-only peer"
//     //         />
//     //         <div className="w-11 h-6 bg-gray-700 rounded-full peer-checked:bg-primary peer-checked:after:translate-x-5 after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
//     //       </label>
//     //     </div>

//     //     {/* New Review Alerts */}
//     //     <div className="flex justify-between items-center">
//     //       <li className="text-lg ">New Review Alerts:</li>
//     //       <label className="relative inline-flex items-center cursor-pointer">
//     //         <input
//     //           type="checkbox"
//     //           checked={reviewAlert}
//     //           onChange={(e) => setReviewAlert(e.target.checked)}
//     //           className="sr-only peer"
//     //         />
//     //         <div className="w-11 h-6 bg-gray-700 rounded-full peer-checked:bg-primary peer-checked:after:translate-x-5 after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
//     //         </label>
//     //     </div>
//     //   </div>
//     // </div>
//     <div class="flex items-start space-x-4 bg-white shadow-md p-4 rounded-lg border border-gray-200 m-10">

//       <div class="flex-shrink-0">
//         <div class="flex items-center justify-center w-12 h-12 bg-red-100 rounded-full">
//           <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
//             <path stroke-linecap="round" stroke-linejoin="round" d="M9 12h6m-6 4h6m-7-9a9 9 0 100 18 9 9 0 100-18z" />
//           </svg>
//         </div>
//       </div>


//       <div class="flex-1">
//         <h3 class="text-lg font-semibold text-gray-900">Booking Cancelled</h3>
//         <p class="text-sm text-gray-600 mt-1">
//           Your Booking with Hotel yuva sakthi has been cancelled. Please contact the hotel for any query.
//         </p>
//         <p class="text-sm font-medium text-gray-500 mt-3">
//           2024-12-07 10:07:59
//         </p>
//       </div>


//       <div class="flex flex-col items-end">
//         <span class="inline-flex items-center justify-center px-2 py-0.5 bg-red-500 text-white text-xs font-semibold rounded-md">
//           New
//         </span>
//         <a href="#" class="text-gray-500 hover:text-gray-700 mt-2">
//           <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
//             <path stroke-linecap="round" stroke-linejoin="round" d="M14 9l6 6m0 0l-6 6m6-6H3" />
//           </svg>
//         </a>
//       </div>
//     </div>

//   )
// }

// export default GuestMessage

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiUrl } from '../../Shared/shared';

function GuestMessage() {
  const [notifications, setNotifications] = useState([]); // Ensure state is initialized as an array
  console.log("notifications",notifications);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.log("Authentication token not found. Please log in again.");
      return;
    }

    const config = {
      headers: {
        "oauth-token": token,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.get(`${apiUrl}/user/notifications`, config);
      setNotifications(response?.data?.data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setNotifications([]); 
    }
  };

  return (
    <div className="m-10">
      {notifications.length > 0 ? (
        (notifications || []).map(notification => (
          <div
            key={notification.id}
            className="flex items-start space-x-4 bg-white shadow-md p-4 rounded-lg border border-gray-200 mb-4"
          >
            {/* Icon */}
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center w-12 h-12 bg-red-100 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-red-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12h6m-6 4h6m-7-9a9 9 0 100 18 9 9 0 100-18z"
                  />
                </svg>
              </div>
            </div>

            {/* Content */}
            <div className="flex-1">
              <h3 className="text-lg font-semibold text-gray-900">
                {notification.title}
              </h3>
              <p className="text-sm text-gray-600 mt-1">{notification.message}</p>
              <p className="text-sm font-medium text-gray-500 mt-3">
                {new Date(notification.createdAt).toLocaleString()}
              </p>
            </div>

            {/* Badge */}
            <div className="flex flex-col items-end">
              {!notification.seen && (
                <span className="inline-flex items-center justify-center px-2 py-0.5 bg-red-500 text-white text-xs font-semibold rounded-md">
                  New
                </span>
              )}
            </div>
          </div>
        ))
      ) : (
        <p className="text-center text-gray-500">No notifications available.</p>
      )}
    </div>
  );
}

export default GuestMessage;
