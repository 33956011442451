// // ../Reducers/Booking.js
// import { SAVE_NUMBER } from "../Actions/types";

// const initialState = {
//   mobile: "",
//   countryCode: "91",
// };

// const SaveNumber = (state = initialState, action) => {
//   const { type, payload } = action;

//   switch (type) {
//     case SAVE_NUMBER:
//       return {
//         ...state,
//         mobile: payload,
//       };

//     default:
//       return state;
//   }
// };

// export default SaveNumber;


// ../Reducers/Booking.js
import { SAVE_NUMBER, SAVE_EMAIL } from "../Actions/types";

const initialState = {
  mobile: "",
  email: "", // Add email field
  countryCode: "91",
};

const SaveNumber = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SAVE_NUMBER:
      return {
        ...state,
        mobile: payload, // Update mobile
      };
    case SAVE_EMAIL:
      return {
        ...state,
        email: payload, // Update email
      };
    default:
      return state;
  }
};

export default SaveNumber;

