import { Box, Typography, Button, Divider, Grid, Chip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLoader } from '../../Reducers/LoaderProvider';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import subscription, { getSubscription } from '../../API/subscription';
import toast from 'react-hot-toast';
import axios from 'axios';
import { apiUrl } from '../../Shared/shared';
import { useRazorpay } from 'react-razorpay';
import CONSTANT from '../../Constant';
import { useNavigate } from 'react-router-dom';
const SubscriptionInfo = () => {
    const user = useSelector((state) => state.Auth);
    const hoteldata = useSelector((state) => state?.HotelData?.hotelData?.data?.hotel);
    const [monthlyPlan, setMonthlyPlan] = useState(null);
    const [data, setData] = useState({})
    const { setLoading } = useLoader();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            fetchData();

        }, 1000); // 1 second delay
    }, []);
    const fetchData = async () => {
        await dispatch(getSubscription(user, setData))
    };
    const isPayNowActive = data && data?.daysLeft <= 6 || !data;


    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };
    const orderPlace = async (paymentId, orderId, signature) => {
        setLoading(true)
        const payload = {
            razorpay_order_id: orderId,
            razorpay_payment_id: paymentId,
            razorpay_signature: signature // Include razorpay_signature in the payload
        };
        setLoading(false)
        dispatch(subscription(payload, fetchData))
        fetchData()
    };

    const handleOrderId = async () => {
        try {
            // Retrieve token from localStorage
            const token = localStorage.getItem("token");
            if (!token) {
                toast.error("Authentication token not found. Please log in again.");
                return;
            }

            // Set the request headers with the OAuth token
            const config = {
                headers: {
                    "oauth-token": token, // Send the token with the oauth-token key in headers
                    "Content-Type": "application/json", // Ensure correct content-type header for JSON data
                },
            };
            try {
                const getSubscription = await axios.get(`${apiUrl}/subscription`, config);
                const monthly = getSubscription.data.data.hotels.rows.find(
                    // (plan) => console.log("plan",plan)
                    (plan) => plan.name.toLowerCase().includes("mothly plan")
                );


                setMonthlyPlan(monthly);
                const payload = {
                    id: monthly.id,
                    name: monthly.name
                }
                // Send the form data to the API
                const response = await axios.post(`${apiUrl}/subscription/order`, payload, config);
                return response.data.data
            }
            catch (error) {
                toast.error('no subscription ')
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    const { error, isLoading, Razorpay } = useRazorpay();
    const handlePayment = async () => {

        const orderid = await handleOrderId(); // Await the order ID

        if (!orderid) {
            console.error("Failed to get order ID");
            return;

        }
        let amount = 300;
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        const options = {
            key: CONSTANT.razorPayKey,
            amount: parseInt(amount * 100),
            // amount: parseInt(1 * 100),
            currency: CONSTANT.currency,
            name: CONSTANT.appName,
            description: hoteldata?.hotelName,
            // order_id: orderid,       // local
            orderid: orderid,    // site
            image: CONSTANT.logoUrl,
            handler: function (response) {
                const { razorpay_payment_id, razorpay_order_id, razorpay_signature } = response;
                console.log('response succc', response)
                console.log("razorpay_payment_id", razorpay_payment_id)
                console.log("razorpay_order_id", razorpay_order_id)
                console.log("razorpay_signature", razorpay_signature)
                orderPlace(razorpay_payment_id, razorpay_order_id, razorpay_signature); // after payment completes on stripe this function will be called and you can do your stuff
            },
            prefill: {
                name: user?.fullName, // project or transaction name
                email: user?.email,
            },
            notes: {
            },
            theme: {
                color: "#ED1C24",
            },
        };
        const successCallback = (paymentId) => {
            console.log('paymentId: ', paymentId);
            //alert (paymentId);
        }
        const faliurCallback = (error) => {
            console.log('error: ', error);
            //alert(error);
        }
        const paymentObject = new window.Razorpay(options);
        paymentObject.open(options);
    };
    return (
        <Box sx={{
            width: "100%"
        }}>
            {data ?
                <Box
                    sx={{
                        margin: { xs: '0px', sm: '30px', md: '30px' },
                        backgroundColor: "white",
                        width: "95%",
                        borderRadius: "10px",
                    }}>

                    <Box sx={{ p: 2, bgcolor: 'white', borderRadius: 2 }}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Subscription Overview
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Grid container spacing={2} sx={{ px: 5 }}>
                            <Grid item xs={6}>
                                <Typography variant="body2">Status</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                <Chip
                                    label={data && data?.status}
                                    sx={{
                                        backgroundColor: data?.status === 'Active' ? '#2FCA44' : '#DF2C14',
                                        color: 'white', // Optional, to ensure the text is white
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2">Days Left</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                <Typography variant="body2" fontWeight="bold">{data?.daysLeft
                                    ? data.daysLeft // for dd-mm-yyyy
                                    : ''} Days</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2">Current Plan</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                <Typography variant="body2">Premium - ₹300/month</Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Current Billing Info */}
                    <Box sx={{ p: 2, bgcolor: 'white', borderRadius: 2, mt: 2 }}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Current Billing Info
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Grid container spacing={2} sx={{ px: 5 }}>
                            <Grid item xs={6}>
                                <Typography variant="body2">Subscription Start</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                <Typography variant="body2">
                                    {data?.subscriptionStart
                                        ? new Date(data.subscriptionStart).toLocaleDateString("en-GB") // for dd-mm-yyyy
                                        : ''}
                                </Typography>

                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2">Last Payment</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                <Typography variant="body2">{data?.lastPayment
                                    ? new Date(data.lastPayment).toLocaleDateString("en-GB") // for dd-mm-yyyy
                                    : ''}</Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Upcoming Billing Info */}
                    <Box sx={{ p: 2, bgcolor: 'white', borderRadius: 2, mt: 2 }}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Upcoming Billing Info
                        </Typography>
                        <Divider sx={{ mb: 2 }} />
                        <Grid container spacing={2} sx={{ px: 5 }}>
                            <Grid item xs={6}>
                                <Typography variant="body2">Next Due Date</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                <Typography variant="body2">{data?.nextDueDate
                                    ? new Date(data.nextDueDate).toLocaleDateString("en-GB") // for dd-mm-yyyy
                                    : ''}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2">Next Billing Date</Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                <Typography variant="body2" color="error">{data?.nextBillingDate
                                    ? new Date(data.nextBillingDate).toLocaleDateString("en-GB") // for dd-mm-yyyy
                                    : ''}</Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Buttons */}

                </Box>
                :
                <Box
                    sx={{
                        margin: { xs: '0px', sm: '30px', md: '30px' },
                        backgroundColor: "white",
                        width: "95%",
                        borderRadius: "10px",
                    }}>
                    <Box sx={{ p: 2, textAlign: 'center', bgcolor: 'white', borderRadius: 2 }}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            You have no  subscription
                        </Typography>
                    </Box>
                </Box>

            }
            <Box sx={{ display: 'flex', justifyContent: 'end', mt: 3, mx: 5, gap: 5, mb: 5 }}>
                <Button variant="outlined" color="primary" sx={{
                    fontSize: { xs: "16px", md: "18px" },
                    fontWeight: "600",
                    color: "#475569",
                    bgcolor: "#4755691A",
                    border: "1px solid #0000001A",
                    borderRadius: "10px",
                    padding: { xs: "5px 10px", md: "5px 10px" },
                    width: { xs: "100%", md: "auto" }, // Full width on small screens
                }} onClick={() => navigate(-1)} >
                    Back
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    disabled={!isPayNowActive}
                    onClick={handlePayment}
                    sx={{
                        cursor: isPayNowActive ? 'pointer' : 'not-allowed',
                        fontSize: { xs: "18px", md: "18px" },
                        fontWeight: "600",
                        color: "#FFFFFF",
                        bgcolor: "#C42A25",
                        borderRadius: "10px",
                        padding: { xs: "5px 10px", md: "10px 20px" }, // Increased padding for better appearance
                        width: { xs: "100%", md: "auto" },
                    }}
                >
                    Pay Now
                </Button>
            </Box>

        </Box>
    );
};

export default SubscriptionInfo;
