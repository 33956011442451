const CONSTANT = {
    // logoUrl: 'https://i.postimg.cc/SKXRwxdM/logo.png',
    // razorPayKey: 'rzp_test_GQuEj28u0PcdFr',
    // appName: 'WELRM',
    // currency: 'INR',
    logoUrl: "https://i.postimg.cc/SKXRwxdM/logo.png",
    // razorPayKey: "rzp_test_nzvLvNqutC7WOx",
    razorPayKey: "rzp_live_WqbUf1Fe9qGtNX",
    appName: "WELRM",
    currency: "INR",
  };
  export default CONSTANT;
  