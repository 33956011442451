import IMAGES from "../theme/ImagePath";

const cardData = [
    {
      cardTitle: "Expand Your Reach",
      cardDetail: "List your hotel and attract more guests",
      cardImg: IMAGES.CARD1, // Example path, adjust accordingly
    },
    {
      cardTitle: "Cost-Effective Listings",
      cardDetail: "Boost your hotel's visibility and bookings",
      cardImg: IMAGES.CARD2, // Ensure the path is correct
    },
    {
      cardTitle: "Boost Your Revenue",
      cardDetail: "Optimize your earnings effortlessly",
      cardImg: IMAGES.CARD3, // Replace with the actual path
    },
  ];
  export default cardData;
  