import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLoader } from '../../Reducers/LoaderProvider';

function TermsAndCondition() {
  const { setLoading } = useLoader();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [setLoading]);

  return (
    <Box
      sx={{
        padding: { xs: '20px 20px', md: '40px 80px' },
        backgroundColor: '#F1F1F1',
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          padding: { xs: '20px', md: '40px' },
          borderRadius: '10px',
          backgroundColor: '#ffffff',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "20px",
            fontWeight: 600,
            marginBottom: '20px',
          }}
        >
          Partner Listing Policy & Terms and Conditions
        </Typography>

        {/* Effective Date Section */}
        <Box sx={{display:'flex',justifyContent:"space-between"}}>

            <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 2, color: '#333', }}>
              Effective Date: <span style={{ color: 'grey' }}>1 July 2024</span>
            </Typography>
            
            <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 2, color: '#333', }}>
              Last Updated Date: <span style={{ color: 'grey' }}>27 Nov 2024</span>
            </Typography>
          
        </Box>
        {/* <Typography variant="body1" sx={{ marginBottom: 4 }}>
          (Add the effective date content here.)
        </Typography> */}

        {/* Definitions Section */}
        <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 2, color: '#333', }}>
          Definitions:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          <strong>“Hotel”</strong>, <strong>"Property"</strong> shall refer to any individual hotel property located in India. Such Hotel may be represented by the owner,
          or any other person duly authorized by the owner.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          <strong>“WelRM”</strong> shall refer collectively to Welrm Hospitality Services Pvt. Ltd., a company registered under the Companies Act, 1956, with its
          registered office at C/O Satpal, opposite Sr. Sec. School Ghanauli, VPO Ghanauli, Distt. Ropar, Rupnagar, Punjab 140113.
        </Typography>

        {/* Scope and Applicability Section */}
        <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 2, color: '#333', }}>
          Scope and Applicability:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          This document contains the terms and conditions, subject to which, a Hotel may list on the WelRM platform. The contents of this policy may be updated by
          WelRM from time to time. Such updates shall be applicable from the date such updated policy is made available on the Platform. In the event of any conflict
          between this policy and the terms and conditions of the Listing Agreement, the terms and conditions of the policy shall prevail.
        </Typography>

        {/* Platform Section */}
        <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 2, color: '#333', }}>
          Platform:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          Any hotel, property may list itself on the WelRM platform at{' '}
          <a href="https://partner.welrm.com/register" target="_blank" rel="noopener noreferrer">
            https://partner.welrm.com/register
          </a>.
        </Typography>

        {/* Eligibility Section */}
        <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 2, color: '#333', }}>
          Eligibility:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          In addition to the criteria mentioned in the Listing Agreement, and the Platform, a hotel must submit the following documents to be listed on the WelRM
          Platform: Pan Card, Aadhar Number, GST Certificate and Address Proof. The list of such documents may be subject to change and shall be periodically updated
          on the platform.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          The list of such documents may be subject to change and shall be periodically updated on the platform.
          WelRM may, at its discretion, verify any of the documents and other details submitted by the hotel.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          Where a Hotel has been previously listed on the platform as part of a hotel chain, franchise or group and seeks relisting as an individual Hotel,
          WelRM reserves the right, but is under no obligation, to seek a no-objection certificate from the relevant chain, franchise or group prior to listing such Hotel.
        </Typography>


        <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 2, color: '#333', }}>
          Listing Agreement:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          A hotel must agree to the terms and conditions of the listing agreement, as updated from time to time, in order to proceed with listing. The listing agreement
          shall be made available on the WelRM Platform. Such listing agreement may be executed between WelRM and the hotel partners.<br /><br />

          Any grievance in respect of any of the clauses of the listing agreement may be raised at <a href="mailto:welrmhotels@gmail.com">welrmhotels@gmail.com</a>.

        </Typography>
        {/* Listing Fee Section */}
        <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 2, color: '#333', }}>
          Listing Fee:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          The current listing fee for hotels executing the standard listing agreement is around Rupees 300/- INR. Any change in the listing fee and its validity
          would be updated on the platform. The final listing fee is also subject to the voluntary promotions, offers, schemes etc. that WelRM may offer hotels
          from time to time.
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          The listing fee for such negotiated agreements is determined based on a variety of factors, including but not limited to the value of the brand,
          occupancy rates, and the number and type of properties associated with the hotel chain. Other considerations include the duration of the partnership, user demand,
          marketing expenditures, location, pricing, promotions, and amenities offered. The availability of comparable properties within the same price and amenities segment,
          the quality of the overall customer experience, average customer ratings, the availability and efficiency of customer relationship management staff, and the
          historical period of association with WelRM also play a role. These factors collectively influence the listing fee and may vary depending on the specific agreement
          and market conditions.
        </Typography>

        {/* Compliance Section */}
        <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 2, color: '#333', }}>
          Compliance with Applicable Laws:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          In addition to the obligations under the Listing Agreement, where required by applicable law, any authority, or where determined appropriate by WelRM
          for any other reason, WelRM may request hotel to submit any documents evidencing hotel’s compliance with applicable laws and regulations.
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 2, color: '#333', }}>
          Conflict:
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          In the event of any conflict between these listing terms and conditions, and the requirements of any applicable, law, regulation, license, authority or order,
          the terms of such applicable law, regulation, license, authority or order shall prevail.
        </Typography>
        {/* Grievance Section */}
        <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 2, color: '#333', }}>
          Grievance:
        </Typography>
        <Typography variant="body1">
          Any grievance in respect of these listing terms and conditions, their application, or their contents may be raised with{' '}
          <a href="mailto:welrmhotels@gmail.com">welrmhotels@gmail.com</a>.
        </Typography>

        {/* Back Button */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 4 }}>
          <Button
            onClick={() => navigate(-1)}
            variant="contained"
            sx={{
              bgcolor: '#E0E0E0',
              color: '#000000',
              textTransform: 'none',
              '&:hover': {
                bgcolor: '#D0D0D0',
              },
              px: 4,
              py: 1,
              borderRadius: '8px',
            }}
          >
            Back
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default TermsAndCondition;
